import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host, Injector,
  Input, OnInit,
  Optional,
  Output,
  signal,
  SkipSelf,
  ViewChild,
  ViewContainerRef, ViewEncapsulation,
} from '@angular/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DEFAULT_DATE_TIME_FORMAT } from '../../pipes/pipes.const';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  APP_DATE_TIME_FORMATS, DATE_TIME_REGEX,
  DEFAULT_MAX_DATE, DEFAULT_MAX_DATE_TIME,
  DEFAULT_MIN_DATE, DEFAULT_MIN_DATE_TIME,
} from '../../constants/form-controls.const';
import { FormInputTimePickerNdComponent } from '../nd-form-input-time-picker/nd-form-input-time-picker.component'
import { DateUtils } from '../../utilities';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import * as moment from 'moment';


@Component({
  selector: 'app-nd-form-input-date-time',
  standalone: true,
  imports: [
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    TranslateModule,
    MatError,
    ValidationMessageComponent,
    FormInputTimePickerNdComponent
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_TIME_FORMATS }],
  templateUrl: './nd-form-input-date-time.component.html',
  styleUrl: './nd-form-input-date-time.component.scss'
})
export class FormInputDateTimeNdComponent implements OnInit {
  @Input() minDate = DEFAULT_MIN_DATE_TIME;
  @Input() maxDate = DEFAULT_MAX_DATE_TIME;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input({
    required: true,
    transform: (value: string) => {
      return 'app-form-input-date-' + value;
    },
  })
  testId: string;
  @Input() disabled: boolean = false;
  @Input() controlName: string;
  @Input() control: AbstractControl;
  required = signal<boolean>(false);


  @Output() datepickerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() datepickerClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDate: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('datePickerInput') datePickerInput: ElementRef;
  datetimeregex = DATE_TIME_REGEX;


  value: moment.Moment;

  timeForm: FormGroup;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    public fb: FormBuilder,
    public viewContainerRef: ViewContainerRef,
    public injector: Injector,
    public cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control) {
      this.setDefaultValues();

      if (this.disabled) {
        this.control.disable();
      }
    }

    this.timeForm = this.fb.group({
      hour: [null,[]],
      minute: [null,[]]
    })
  }

  onDatepickerOpened(): void {
    const overlayContainer = document.querySelector('.mat-calendar-content');
    if (overlayContainer) {
      const existingPicker = overlayContainer.querySelector('.custom-time-picker');
      if (existingPicker) {
        existingPicker.remove();
      }
      const timePickerRef = this.viewContainerRef.createComponent(FormInputTimePickerNdComponent,{
        injector: Injector.create({
          providers: [
            { provide: 'TIME_FORM', useValue: this.timeForm },
          ],
          parent: this.injector,
        })
      });
      const container = document.createElement('div');
      container.className = 'custom-time-picker';
      container.appendChild(timePickerRef.location.nativeElement)
      overlayContainer.appendChild(container);
    }
    this.datepickerOpened.emit();
  }

  onDatepickerClosed(): void {
    const date = this.control.value as moment.Moment;
    const {hour, minute} = this.timeForm.getRawValue();
    if (this.timeForm.getRawValue()) {
      const updatedDate:Date = date.toDate();
      updatedDate.setHours(hour);
      updatedDate.setMinutes(minute);
      this.control.patchValue(moment(updatedDate), { emitEvent: true });
      const inputElement = document.querySelector('#datePickerInput') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = this.control.value; // Ensure it matches the patched value
      }
      this.cdr.detectChanges();
      this.cdr.detectChanges();
      this.changeDate.emit();
    }

    this.datepickerClosed.emit();
  }

  chosenMonthHandler(
    normalizedMonth,
    datepicker: MatDatepicker<moment.Moment>,
  ) {
    if (this.startView == 'multi-year') {
      normalizedMonth.date(1);
      datepicker.select(normalizedMonth);
      datepicker.close();
    }
  }

  /** When date is picked from the date picker or manually entered
   */
  onChangeDate() {
    this.changeDate.emit();
  }

  /** When date is entered manually
   */
  valueChangeHandler(event$) {
    const date = event$?.target.value
      ? DateUtils.format(event$.target.value, DEFAULT_DATE_TIME_FORMAT)
      : this.control.value;
    if (!date){
      this.timeForm.reset()
      return;
    }
    const dateString = event$?.target.value ? event$?.target.value : this.control.value;
    const selectedDate = date as moment.Moment
    this.timeForm.patchValue({
      hour: selectedDate.hour(),
      minute: selectedDate.minute(),
    })
    if (!DateUtils.isValidDateTime(dateString, DEFAULT_DATE_TIME_FORMAT)) {
      this.control.setErrors({ matDatepickerParse: true });
    }else{
      if(date.isAfter(this.maxDate)) this.control.setErrors({matDatepickerMax: true})
      else if(date.isBefore(this.minDate)) this.control.setErrors({matDatepickerMin: true})
      else this.control.setErrors(null)
    }
  }

  fixRequiredError(event$: Event) {
    const date = (event$?.target as HTMLInputElement).value;
    if (date) {
      const errors = this.control.errors || {};
      delete errors['required'];
      this.control.setErrors(Object.keys(errors).length ? errors : null);
    }
  }
  get minDateValue() {
    return this.minDate ?? DEFAULT_MIN_DATE;
  }
  get maxDateValue() {
    return this.maxDate ?? DEFAULT_MAX_DATE;
  }

  setDefaultValues() {
    this.required.set(this.control.hasValidator(Validators.required));
  }

  protected readonly DEFAULT_MIN_DATE = DEFAULT_MIN_DATE;
  protected readonly DEFAULT_MAX_DATE = DEFAULT_MAX_DATE;
}
